import React from 'react';
import { Badge } from 'react-bootstrap';
import { LOAN_STATES } from '../../utils/constants';
import _ from 'lodash';

export function LoanStatusText (props: { loan: { status: string, withdrawalReason: string } }) {
  let status = props.loan.status;
  if (status === LOAN_STATES.EXPIRED) {
    status = `CANCELED (${ props.loan.withdrawalReason })`
  }
  return <>{ status }</>
}

export default function LoanStatusBadge (props: { status: string, className?: string }) {
  let pillVariant: React.ComponentProps<typeof Badge>['variant'];
  let status = props.status;
  switch (props.status) {
    case LOAN_STATES.APPROVED:
    case LOAN_STATES.ACCEPTED:
      pillVariant = 'info';
      break;
    case LOAN_STATES.AUTHORIZED:
    case LOAN_STATES.FUNDED:
      pillVariant = 'warning';
      break;
    case LOAN_STATES.REPAID:
      pillVariant = 'success';
      break;
    case LOAN_STATES.PASTDUE:
      pillVariant = 'danger';
      break;
    case LOAN_STATES.EXPIRED:
      pillVariant = 'secondary';
      status = 'CANCELED';
      break;
    default:
      pillVariant = 'primary';
  }
  return (
    <Badge pill variant={ pillVariant } className={ props.className }>
      { _.capitalize(status) }
    </Badge>
  );
}
