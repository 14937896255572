import React from 'react';

export default function LinkIcon (props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2532_19)">
        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M3.28596 8.94281C2.78586 9.44291 2.50491 10.1212 2.50491 10.8284C2.50491 11.5357 2.78586 12.214 3.28596 12.714C3.78606 13.2141 4.46433 13.4951 5.17158 13.4951C5.52177 13.4951 5.86853 13.4261 6.19207 13.2921C6.5156 13.1581 6.80957 12.9617 7.0572 12.714L8.47141 11.2998C8.73176 11.0395 9.15387 11.0395 9.41422 11.2998C9.67457 11.5602 9.67457 11.9823 9.41422 12.2426L8.00001 13.6569C7.62857 14.0283 7.18762 14.3229 6.70231 14.5239C6.21701 14.725 5.69687 14.8284 5.17158 14.8284C4.11071 14.8284 3.0933 14.407 2.34315 13.6569C1.59301 12.9067 1.17158 11.8893 1.17158 10.8284C1.17158 9.76756 1.59301 8.75015 2.34315 8L3.75737 6.58579C4.01771 6.32544 4.43982 6.32544 4.70017 6.58579C4.96052 6.84614 4.96052 7.26825 4.70017 7.5286L3.28596 8.94281ZM6.58579 4.70017C6.32544 4.43982 6.32544 4.01771 6.58579 3.75736L8.00001 2.34315C8.37144 1.97171 8.8124 1.67708 9.2977 1.47606C9.783 1.27504 10.3031 1.17158 10.8284 1.17158C11.3537 1.17158 11.8739 1.27504 12.3592 1.47606C12.8445 1.67708 13.2854 1.97171 13.6569 2.34315C14.0283 2.71458 14.3229 3.15554 14.524 3.64084C14.725 4.12614 14.8284 4.64629 14.8284 5.17158C14.8284 5.69686 14.725 6.21701 14.524 6.70231C14.3229 7.18761 14.0283 7.62857 13.6569 8L12.2426 9.41422C11.9823 9.67457 11.5602 9.67457 11.2998 9.41422C11.0395 9.15387 11.0395 8.73176 11.2998 8.47141L12.7141 7.05719C12.9617 6.80957 13.1581 6.5156 13.2921 6.19206C13.4261 5.86853 13.4951 5.52177 13.4951 5.17158C13.4951 4.82138 13.4261 4.47462 13.2921 4.15109C13.1581 3.82755 12.9617 3.53358 12.7141 3.28596C12.4664 3.03833 12.1725 2.84191 11.8489 2.7079C11.5254 2.57388 11.1786 2.50491 10.8284 2.50491C10.4782 2.50491 10.1315 2.57388 9.80794 2.7079C9.48441 2.84191 9.19044 3.03833 8.94282 3.28596L7.5286 4.70017C7.26825 4.96052 6.84614 4.96052 6.58579 4.70017Z"/>
        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M5.64298 10.357C5.38263 10.0967 5.38263 9.67457 5.64298 9.41422L9.41422 5.64298C9.67457 5.38263 10.0967 5.38263 10.357 5.64298C10.6174 5.90333 10.6174 6.32544 10.357 6.58579L6.58579 10.357C6.32544 10.6174 5.90333 10.6174 5.64298 10.357Z" />
      </g>
      <defs>
        <clipPath id="clip0_2532_19">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}
