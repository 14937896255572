import * as React from 'react';
import copy from 'clipboard-copy';

export default function ClickToCopy (props: { text: string, className?: string, children: React.ReactNode }) {
  const [isAnimating, setIsAnimating] = React.useState(false);

  React.useEffect(() => {
    if (isAnimating) {
      const timeout = setTimeout(() => setIsAnimating(false), 1000);
      return () => clearTimeout(timeout);
    }
  }, [isAnimating]);

  function onClick () {
    copy(props.text);
    setIsAnimating(true);
  }

  return (
    <span
      title={ isAnimating ? 'Copied!' : 'Click to copy' }
      style={{ cursor: isAnimating ? 'default' : 'pointer' }}
      className={ props.className }
      onClick={ onClick }
    >
      { props.children }
      { isAnimating && <span> ✓</span> }
    </span>
  );
}
