import React from 'react';

import KustomerLink from '../KustomerLink';
import styles from './LoanApplicationOverviewHeader.module.scss'
import { Col, Row } from 'react-bootstrap';
import { User } from '../../api/queries/users/useUser';
import ClarityLink from '../ClarityLink';
import ActionsDropdown from '../ActionsDropdown';
import PausedUserAlert from './PausedUserAlert';
import MarqetaLinks from '../MarqetaLinks';
import { LOAN_APPLICATION_SOURCES, LOAN_APPLICATION_STATUSES, UNDERWRITING_OUTCOMES } from '../../utils/constants';
import LoanSlug from '../LoanSlug';
import PausedBadge from '../Badges/PausedBadge';
import LoanPartnerBadge from '../Badges/LoanPartnerBadge';
import { formatDollars } from '../../utils';
import LoanModificationBadge from '../Badges/LoanModificationBadge';
import LoanStatusBadge from '../Badges/LoanStatusBadge';
import FundingPeriodBadge from '../Badges/FundingPeriodBadge';
import LoanPastDueDaysBadge from '../Badges/LoanPastDueDaysBadge';
import LoanCollectionsStatusBadge from '../Badges/LoanCollectionsStatusBadge';
import LoanSpecialProvisionsBadge from '../Badges/LoanSpecialProvisionsBadge';
import LoanPortfolioBadge from '../Badges/LoanPortfolioBadge';
import { Loan } from '../../api/queries/types';
import { LegacyLoanApplication } from '../../api/queries/users/useLoanApplications';
import LoanApplicationStatusBadge from '../Badges/LoanApplicationStatusBadge';
import { get } from 'lodash';
import LoanApplicationFromAgentBadge from '../Badges/LoanApplicationFromAgentBadge';
import useLatest from '../../api/queries/underwriting-decisions/useLatest';
import LoanApplicationUnderwritingDecisionBadge from '../Badges/LoanApplicationUnderwritingDecisionBadge';
import { LoanSummary } from '../../api/queries/users/useLoansSummaries';
import POABadge from '../Badges/POABadge';
import TrelloLinks from '../TrelloLinks';
import { Header } from '../../pages/Layouts/Layout';
import ClickToCopy from '../ClickToCopyText';
import ClickToCopyButton from '../ClickToCopyButton';
import LinkIcon from '../../images/LinkIcon';

type Props = {
  user?: User;
  mutateContactUser: () => void;
  loan?: Loan
  loanApplication: LegacyLoanApplication;
  contactUser?: User;
};

export default function LoanApplicationOverviewHeader ({ user, mutateContactUser, loan, loanApplication, contactUser }: Props) {


  if (!user || !contactUser) { return null; }

  return (
    <>
    <Header>
      <div className={'d-flex w-100 align-items-center'}>
        <div>
          { loanApplication.firstName } { loanApplication.lastName }
          { (loanApplication.principal || loan) && <span className='mx-2'>•</span> }
          {
            (loan?.principal || loanApplication.principal) ? (
              <span>{formatDollars(loan?.principal ?? loanApplication.principal, { precision: 0 })}</span>
            ) : null
          }
          { loan ? <ClickToCopy text={ loan.slug }><LoanSlug className='text-muted ml-2'>{ loan.slug }</LoanSlug></ClickToCopy> : null }
        </div>
        <div className='d-flex ml-auto my-auto'>
          <ClarityLink block={ true } user={ contactUser } className={`ml-2 mt-0 ${styles.buttonLink} btn-link border-0 bg-transparent`} />
          <KustomerLink
            block={ true }
            kustomerId={contactUser.kustomerId}
            syncEndpoint={`/notebook/users/${contactUser._id}/sync-kustomer-profile`}
            onSyncToKustomer={ mutateContactUser }
            className={`ml-2 mt-0 ${styles.buttonLink} btn-link border-0 bg-transparent`}
          />
          <MarqetaLinks marqetaUsers={user.marqetaUsers} className={`ml-2 mt-0 ${styles.buttonLink} btn-link border-0 bg-transparent`}/>
          <TrelloLinks loanApplication={ loanApplication} className={`ml-2 mt-0 ${styles.buttonLink} btn-link border-0 bg-transparent`} />
          <ClickToCopyButton tooltip={ loan ? 'Copy loan URL' : 'Copy application URL' } icon className='ml-2' text={ window.location.href }>
            <LinkIcon />
          </ClickToCopyButton>
          <ClickToCopyButton tooltip='Copy internal ID' icon className='ml-2' text={ loan?._id ?? loanApplication._id }>
            ID
          </ClickToCopyButton>
          <ActionsDropdown user={ contactUser } loan={loan} className='ml-2' />
        </div>
      </div>
    </Header>
      <div className='d-block w-100'>
        { contactUser.is_paused &&
          <Row className='w-100 ml-0 pb-2'>
            <Col className='px-4 pt-3'>
              <PausedUserAlert user={ contactUser } />
            </Col>
          </Row>
        }
        <div className={`d-flex align-items-center py-3 px-4 mb-1 border-bottom ${styles.badges}`} data-qa='badges'>
          <LoanBadges loan={ loan } loanApplication={ loanApplication } />
        </div>

      </div>

  </>
  );
}

export function LoanBadges ({ loan, children, loanApplication }: { loan?: Loan | LoanSummary, loanApplication?: LegacyLoanApplication, children?: React.ReactNode }) {
  let partnerId, showPastDueBadge;

  if (loan) {
    partnerId = loan.partnerId;
    showPastDueBadge = loan.daysPastDue && loan.daysPastDue > 0
  } else if (loanApplication) {
    partnerId = loanApplication.partner._id;
  } else {
    partnerId = null;
  }

  return (<>
    <LoanPartnerBadge partnerId={ partnerId! } source={ loan?.loanApplicationSource ?? (loanApplication?.isSourceBetter ? LOAN_APPLICATION_SOURCES.BETTER : LOAN_APPLICATION_SOURCES.STATED) } />
    { loan ? (<>
      <LoanPortfolioBadge portfolio={ loan.portfolio! } />
      <LoanStatusBadge status={ loan.status } />
      <FundingPeriodBadge status={ loan.status } />
      { loan.hasModifications && <LoanModificationBadge /> }
      { showPastDueBadge && <LoanPastDueDaysBadge days={ loan.daysPastDue } /> }
      <LoanCollectionsStatusBadge collectionsStatus={ loan.collectionsStatus } />
      <LoanSpecialProvisionsBadge specialProvisions={ loan.specialProvisions } />
    </>) : loanApplication ? (<>
      <LoanApplicationOnlyBadges loanApplication={ loanApplication } />
    </>) : null }

    {
      loanApplication ? (<>
        { loanApplication.isUserPaused && <PausedBadge/> }
        { loanApplication.isPOA && <POABadge/> }
      </>) : null
    }

    { children }
  </>);
}

function LoanApplicationOnlyBadges ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  const { data: underwritingDecision } = useLatest(loanApplication._id, loanApplication.status === LOAN_APPLICATION_STATUSES.CLOSED);
  const ineligibleLoanApp = underwritingDecision && underwritingDecision.outcome === UNDERWRITING_OUTCOMES.INELIGIBLE;
  const agentLoanApp = get(loanApplication,'underwritingResult.LOAN_APPLICATION_FROM_AGENT.failed', false);
  return (
    <>
      <LoanApplicationStatusBadge status={ loanApplication.status } />
      { ineligibleLoanApp && <LoanApplicationUnderwritingDecisionBadge outcome={underwritingDecision.outcome} /> }
      { agentLoanApp && <LoanApplicationFromAgentBadge /> /* Legacy badge */ }
    </>
  );
}
