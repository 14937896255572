import * as React from 'react';
import copy from 'clipboard-copy';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './ClickToCopyButton.module.css';
import cx from 'classnames';

export default function ClickToCopyButton (props: {
  text: string;
  className?: string;
  children: React.ReactNode;
  icon?: boolean;
  tooltip?: React.ReactNode;
}) {
  const [isAnimating, setIsAnimating] = React.useState(false);

  React.useEffect(() => {
    if (isAnimating) {
      const timeout = setTimeout(() => setIsAnimating(false), 1000);
      return () => clearTimeout(timeout);
    }
  }, [isAnimating]);

  function onClick () {
    copy(props.text);
    setIsAnimating(true);
  }

  const content = (
    <Button
      title={ isAnimating ? 'Copied!' : 'Click to copy' /* TODO: use popover */ }
      className={ cx(styles.component, props.className, props.icon ? styles.icon : false) }
      onClick={ onClick }
      data-is_animating={ isAnimating }
      variant='outline-secondary'
    >
      <span className={styles.content}>{ props.children }</span>
      { isAnimating && <span className={styles.checkmark}> ✓</span> }
    </Button>
  );

  if (props.tooltip) {
    return (
      <OverlayTrigger
        placement = 'bottom'
        overlay={
          <Tooltip id=''>
            { props.tooltip }
          </Tooltip>
        }
      >
        { content }
      </OverlayTrigger>
    )
  }

  return content;
}
