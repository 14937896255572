import React from 'react';
import Partners from '../../../components/System/Partners/Partners';
import useExpandableState from '../../../utils/useExpandableState';
import { Button } from 'react-bootstrap';
import NewPartnerModal from '../../../components/Modals/NewPartnerModal';
import { usePartners } from '../../../api/queries/partners/usePartners';
import { Header } from '../../Layouts/Layout';

export default function PartnersPage () {
  const partnersRequest = usePartners();
  const newPartnerModal = useExpandableState();

  if (!partnersRequest.data) {
    return null;
  }
  const partners = partnersRequest.data;

  return (
    <div className='searchPage mb-5'>
      <Header>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-white m-0 p-0 align-items-center">
            <li className="breadcrumb-item">System</li>
            <li className="breadcrumb-item active" aria-current="page">Partners</li>
            <div className="d-flex ml-auto">
              <Button
                size='sm'
                variant='outline-secondary'
                onClick={() => newPartnerModal.showExpand()}
              >
                Add partner
              </Button>
            </div>
          </ol>
        </nav>
      </Header>
      <Partners partners={partners} showAddNew={false}/>

      <NewPartnerModal
        expandable={newPartnerModal}
        partners={partners}
      />
    </div>
  );
}
