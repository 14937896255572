import * as React from 'react';
import copy from 'clipboard-copy';

export default function LoanSlug (props: { className?: string, children: React.ReactNode }) {
  let onClick, style, title;
  if (typeof props.children === "string") {
    style = { cursor: 'pointer' };
    title   = 'Click to copy';
    onClick = () => { copy(props.children as string) };
  }
  return (
    <span data-qa='slug' style={ style } title={ title } className={`text-uppercase ${props.className ? props.className : ''}`} onClick={ onClick }>
      { props.children }
    </span>
  );
}
