import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { get } from 'lodash';
import { LegacyLoanApplication } from '../api/queries/users/useLoanApplications';

const PROPERTY_INFO = 'PROPERTY_INFO';
const PERSON_INFO = 'PERSON_INFO';

export default function TheNumberLink (props: { loanApplication: LegacyLoanApplication } & React.ComponentPropsWithoutRef<typeof Dropdown>) {
    const { loanApplication, ...rest } = props;
    return (
        <Dropdown  {...rest}>
            <Dropdown.Toggle id='the-number-link' variant="outline-secondary" style={{ width: '100%' }}>
                TheNumber
            </Dropdown.Toggle>
            <Dropdown.Menu title="">
                <Dropdown.Item { ...getLinkAttrs(loanApplication, PROPERTY_INFO) }>Property Info</Dropdown.Item>
                <Dropdown.Item { ...getLinkAttrs(loanApplication, PERSON_INFO) }>Person Info</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export function TheNumberPropertyLink (props: { loanApplication: LegacyLoanApplication, children: React.ReactNode }) {
    return (
        <a { ...getLinkAttrs(props.loanApplication, PROPERTY_INFO) }>
            { props.children }
        </a>
    );
}

export function TheNumberPersonLink (props: { loanApplication: LegacyLoanApplication, children: React.ReactNode }) {
    return (
        <a { ...getLinkAttrs(props.loanApplication, PERSON_INFO) }>
            { props.children }
        </a>
    );
}

function getLinkAttrs (loanApplication: LegacyLoanApplication, linkType: string) {
    let href;

    switch (linkType) {
        case PROPERTY_INFO:
            href = theNumberPropertyURL(loanApplication);
            break;
        case PERSON_INFO:
            href = theNumberPersonURL(loanApplication);
            break;
         default:
    }

    return {
        href     : href,
        disabled : !href,
        target   : '_blank',
        rel      : 'noopener noreferrer',
        title    : 'View on TheNumber »'
    };
}

function theNumberPropertyURL (loanApplication: LegacyLoanApplication) {
    if (!loanApplication.listing_address_line1) {
        return;
    }
    return `https://flashlight.thenumber.com/#/analytics/record/property?type=custom&org=${loanApplication.partner.theNumberOrg}&customPrototype=${
        encodeURIComponent(JSON.stringify({
            'street_address': (loanApplication['listing_address_line1'] + ' ' + get(loanApplication, 'listing_address_line2', '')).trim(),
            'city': loanApplication['listing_city'],
            'state': loanApplication['listing_state'],
            'zip': loanApplication['listing_zip'],
        }))
    }`;
}

function theNumberPersonURL (loanApplication: LegacyLoanApplication) {
    return `https://flashlight.thenumber.com/#/analytics/record/summary?org=${loanApplication.partner.theNumberOrg}&portfolio=production_records&key=${ loanApplication['_id'] }`;
}
