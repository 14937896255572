import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import PauseUserModal from './Modals/PauseUserModal';
import { useAPIData, useExpandableState } from '../utils';
import UnpauseUserModal from './Modals/UnpauseUserModal';
import { CollapsableIcon } from './CollapsableIcon';
import { Loan } from '../api/queries/types';
import { LOAN_STATES, PURCHASE_METHODS } from '../utils/constants';
import MarkLoanAuthorizedModal from './Modals/MarkLoanAuthorizedModal';
import MarkLoanCompletedModal from './Modals/MarkLoanCompletedModal';
import MarkLoanCanceledModal from './Modals/MarkLoanCanceledModal';
import MarkLoanRepaidModal from './Modals/MarkLoanRepaidModal';
import AdminContext, { ROLES } from '../adminContext';
import MarkLoanChargedOffModal from './Modals/MarkLoanChargedOffModal';
import ModifyLoanModal from './Modals/ModifyLoanModal';
import LendingAgreementModal from './Modals/LendingAgreementModal';
import useMembershipAgreement from '../api/queries/loans/useMembershipAgreement';

type Props = {
  className?: string;
  user: { _id: string, is_paused: boolean };
  loan?: Loan;
}

export default function ActionsDropdown (props: Props) {
  const { className, user, loan } = props;
  const adminContext = React.useContext(AdminContext);
  const pauseUserModalState = useExpandableState();
  const unpauseUserModalState = useExpandableState();

  return (
    <>
      <Dropdown alignRight={true}>
        <Dropdown.Toggle
          className={className}
          id="dropdown-basic"
          bsPrefix=" "
          variant="outline-secondary"
          size="sm"
        >
          Actions
          <CollapsableIcon className='ml-1 mr-0' open={false}/>
        </Dropdown.Toggle>

        <Dropdown.Menu className='p-0'>
          { !!loan && <LoanActions loan={loan} /> }

          { adminContext.adminRole === ROLES.SUPERADMIN &&
            <>
              <hr className='m-0'/>

              { user.is_paused ?
                <Dropdown.Item onClick={() => unpauseUserModalState.showExpand()} className='small-dropdown-item px-3 py-2'>
                  Unpause user
                </Dropdown.Item> :
                <Dropdown.Item onClick={() => pauseUserModalState.showExpand()} className='small-dropdown-item px-3 py-2'>
                  Pause user
                </Dropdown.Item>
              }
            </>
          }
        </Dropdown.Menu>
      </Dropdown>
      <PauseUserModal
        expand={pauseUserModalState}
        userId={user._id}
        onSubmit={() => window.location.reload()}
      />
      <UnpauseUserModal
        expand={unpauseUserModalState}
        userId={user._id}
        onSubmit={() => window.location.reload()}
      />
    </>
  );
}

export function LoanActions ({ loan }: { loan: Loan }) {
  const adminContext = React.useContext(AdminContext);
  const markLoanAuthorizedModal = useExpandableState();
  const markLoanCompletedModal = useExpandableState();
  const markLoanCanceledModal = useExpandableState();
  const markLoanRepaidModal = useExpandableState();
  const markLoanChargedOffModal = useExpandableState();
  const modifyLoanModal = useExpandableState();
  const lendingAgreementModal = useExpandableState();

  const hasModifications = loan.hasModifications;
  const contractReq = useAPIData(`/notebook/loans/${ loan._id }/contract/lending_agreement`, { abort: !hasModifications });
  const cardholderAgreementReq = useAPIData(`/notebook/loans/${ loan._id }/contract/cardholder_agreement`);
  const membershipAgreementReq = useMembershipAgreement(loan._id);

  return (
    <>
      { loan.status === LOAN_STATES.ACCEPTED &&
        <Dropdown.Item onClick={ markLoanAuthorizedModal.showExpand } className='small-dropdown-item px-3 py-2'>
          Authorize Loan
        </Dropdown.Item>
      }

      { loan.status === LOAN_STATES.FUNDED &&
        <Dropdown.Item onClick={ markLoanCompletedModal.showExpand } className='small-dropdown-item px-3 py-2'>
          End Draw Period <br/><small>(Mark Loan Completed)</small>
        </Dropdown.Item>
      }

      { (loan.status === LOAN_STATES.APPROVED ||
        loan.status === LOAN_STATES.ACCEPTED ||
        loan.status === LOAN_STATES.AUTHORIZED) &&
        <Dropdown.Item onClick={ markLoanCanceledModal.showExpand } className='small-dropdown-item px-3 py-2'>
          Cancel Loan
        </Dropdown.Item>
      }

      { (loan.status === LOAN_STATES.COMPLETED ||
        loan.status === LOAN_STATES.FUNDED ||
        loan.status === LOAN_STATES.PASTDUE) &&
        <Dropdown.Item onClick={ markLoanRepaidModal.showExpand } className='small-dropdown-item px-3 py-2'>
          Mark Loan Repaid
        </Dropdown.Item>
      }

      {
        adminContext.adminRole === ROLES.SUPERADMIN && [LOAN_STATES.AUTHORIZED, LOAN_STATES.FUNDED, LOAN_STATES.COMPLETED].includes(loan.status) &&
        <>
          { [LOAN_STATES.FUNDED, LOAN_STATES.COMPLETED].includes(loan.status) && !loan.chargedOffDate &&
            <Dropdown.Item onClick={ markLoanChargedOffModal.showExpand } className='small-dropdown-item px-3 py-2'>
              Mark Loan Charged-off
            </Dropdown.Item>
          }
          <Dropdown.Item onClick={ modifyLoanModal.showExpand } className='small-dropdown-item px-3 py-2'>
            Modify Loan
          </Dropdown.Item>
        </>
      }

      { loan.hasModifications ?
          <Dropdown.Item onClick={ lendingAgreementModal.showExpand } className='small-dropdown-item px-3 py-2'>
            View Loan Agreement
          </Dropdown.Item>
        : loan.contract &&
          <Dropdown.Item href={ loan.contract.url } className='small-dropdown-item px-3 py-2'>
            View Loan Agreement
          </Dropdown.Item>
      }

      { loan.purchaseMethod === PURCHASE_METHODS.PHYSICAL_CARD && cardholderAgreementReq.data &&
        <Dropdown.Item href={ cardholderAgreementReq.data.url } className='small-dropdown-item px-3 py-2'>
          View Cardholder Agreement
        </Dropdown.Item>
      }

      { membershipAgreementReq.data &&
        <Dropdown.Item href={ membershipAgreementReq.data.url } className='small-dropdown-item px-3 py-2'>
          View Account Agreement
        </Dropdown.Item>
      }

      <MarkLoanAuthorizedModal
        expand   = { markLoanAuthorizedModal }
        onSubmit = {() => { window.location.reload(); }}
        loan     = { loan }
      />

      <MarkLoanCompletedModal
        expand   = { markLoanCompletedModal }
        onSubmit = { () => { window.location.reload() } }
        loan     = { loan }
      />

      <MarkLoanCanceledModal
        expand   = { markLoanCanceledModal }
        onSubmit = {() => { window.location.reload(); }}
        loan     = { loan }
      />

      <MarkLoanRepaidModal
        expand   = { markLoanRepaidModal }
        onSubmit = {() => { window.location.reload(); }}
        loan     = { loan }
      />

      <MarkLoanChargedOffModal
        expand   = { markLoanChargedOffModal }
        onSubmit = { () => { window.location.reload() } }
        loan     = { loan }
      />

      <ModifyLoanModal
        expand   = { modifyLoanModal }
        onSubmit = { () => { window.location.reload() } }
        loan     = { loan }
      />

      <LendingAgreementModal
        show     = { lendingAgreementModal.isExpanded }
        onHide   = { lendingAgreementModal.hideExpand }
        contract = { contractReq.data }
      />
    </>
  );
}
